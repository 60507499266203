
import { defineComponent, ref, unref, toRefs, onMounted, watch, computed } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { ElMessage, ElForm } from 'element-plus';

import { Rule, RuleItem } from 'async-validator';

import { useCampaignCommission, useUpdateCampaignCommission } from '@/composables/api';
import { getCampaign, ResponseError, UPDATED_SUCCESSFULLY_TEXT } from '@/services/api';
import { LevelCommission, PartialCampaignCommission } from '@/interfaces';
import { normalizeRequestData } from '@/utils/normalization';
import { isValidNumberString } from '@/utils/validate';

const DEFAULT_FORM_VALUES: PartialCampaignCommission = {
  initCommissionPercent: '',
  rebillCommissionPercent: '',
  levelCommissions: []
};

export default defineComponent({
  props: {
    isEdit: {
      type: Boolean,
      default: false
    }
  },
  setup(props) {
    const { isEdit } = toRefs(props);
    const router = useRouter();
    const campaignId = useRoute().params.id as string;
    const campaign = ref();
    const formRef = ref<typeof ElForm>();
    const levelCommissionsErrorMessage = ref();

    const formValues = ref({ ...DEFAULT_FORM_VALUES });
    const { isLoading: isUpdating, mutate: update } = useUpdateCampaignCommission();
    const { data, dataUpdatedAt } = useCampaignCommission({ campaignId }, {
      enabled: isEdit.value,
      refetchOnWindowFocus: !isEdit.value
    });

    const fetchCampaign = async() => {
      const { data } = await getCampaign({ campaignId });
      campaign.value = data;
    };

    const addNewLevel = (level: number) => {
      if (formValues.value.levelCommissions.length < 6) {
        formValues.value.levelCommissions = [...formValues.value.levelCommissions, {
          level: level,
          threshold: '',
          percentage: ''
        }];
      }
    };

    // to prevent sending empty object when submitting the form
    const removeEmptyLevelCommissionItems = () => {
      formValues.value.levelCommissions = formValues.value.levelCommissions.filter(item => {
        return (item.threshold !== '' || item.percentage !== '');
      });
    };

    watch(dataUpdatedAt, () => {
      const campaignData = data.value.data;

      formValues.value = {
        ...campaignData
      };
    });

    const isValidateLevelCommissions = (levelCommissions: LevelCommission[]) => {
      let result = true;

      levelCommissions.every(item => {
        if (item.threshold === '' || item.percentage === '') {
          result = false;
          levelCommissionsErrorMessage.value = '同一階層的分潤金額及分潤分成欄位為必填';
          return false;
        }
        if (!item.threshold.match(/^[+-]?([0-9]+([.][0-9]*)?|[.][0-9]+)$/) || !item.percentage.match(/^[+-]?([0-9]+([.][0-9]*)?|[.][0-9]+)$/)) {
          result = false;
          levelCommissionsErrorMessage.value = '請填入數字';
          return false;
        }
      });

      return result;
    };

    const rules = computed(() => {
      return {
        initCommissionPercent: [{
          required: true,
          trigger: 'blur',
          validator: (rule: RuleItem, value: string) => {
            if (!rule.required && !value) {
              return true;
            }

            return isValidNumberString(value);
          }
        }],
        rebillCommissionPercent: [{
          required: true,
          trigger: 'blur',
          validator: (rule: RuleItem, value: string) => {
            if (!rule.required && !value) {
              return true;
            }

            return isValidNumberString(value);
          }
        }],
        levelCommissions: [
          {
            required: false,
            trigger: 'change',
            validator: (rule: RuleItem, levelCommissions: LevelCommission[]) => {
              if (!rule.required && !levelCommissions) {
                return true;
              }

              return isValidateLevelCommissions(levelCommissions);
            },
            message: levelCommissionsErrorMessage.value
          }
        ]
      };
    });

    const submitForm = () => {
      const form = unref(formRef);

      form && form.validate((valid: boolean) => {
        if (valid) {
          if (isEdit.value) {
            removeEmptyLevelCommissionItems();
            const updateFormValues = {
              initCommissionPercent: formValues.value.initCommissionPercent,
              rebillCommissionPercent: formValues.value.rebillCommissionPercent,
              levelCommissions: formValues.value.levelCommissions
            };

            update(
              {
                campaignId,
                data: normalizeRequestData(updateFormValues)
              },
              {
                onSuccess() {
                  ElMessage.success(UPDATED_SUCCESSFULLY_TEXT);

                  router.go(-1);
                },
                onError(error: ResponseError) {
                  ElMessage.error(error.response?.data.message);
                }
              }
            );
          }
        }
      });
    };

    onMounted(() => {
      formValues.value = DEFAULT_FORM_VALUES;
      fetchCampaign();
    });

    return {
      rules,
      formRef,
      formValues,
      isUpdating,
      campaign,
      addNewLevel,
      submitForm
    };
  }
});
